import type { EnvironmentConfig } from '@/types';
import { GlsFeatureFlagsConfig } from 'gls-feature-flags';

const devEnvironment: EnvironmentConfig = {
    VITE_API: 'https://api-dev.gls-group.net',
    VITE_AZURE_B2C_AUTHORITY: 'https://glsgrouptest.b2clogin.com/glsgrouptest.onmicrosoft.com',
    VITE_AZURE_B2C_CLIENT_ID: 'a0462fe1-7da4-4d67-883f-290bcbde8d39',
    VITE_AZURE_B2C_KNOWN_AUTHORITIES: 'glsgrouptest.b2clogin.com',
    VITE_AZURE_AD_CLIENT_ID: '342c952f-c3ec-4368-893d-c96f8db19625'
};

const stageEnvironment: EnvironmentConfig = {
    VITE_API: 'https://api-qas.gls-group.net',
    VITE_AZURE_B2C_AUTHORITY: 'https://glsgrouptest.b2clogin.com/glsgrouptest.onmicrosoft.com',
    VITE_AZURE_B2C_CLIENT_ID: 'a0462fe1-7da4-4d67-883f-290bcbde8d39',
    VITE_AZURE_B2C_KNOWN_AUTHORITIES: 'glsgrouptest.b2clogin.com',
    VITE_AZURE_AD_CLIENT_ID: '342c952f-c3ec-4368-893d-c96f8db19625'
};
const prodEnvironment: EnvironmentConfig = {
    VITE_API: 'https://api.gls-group.net',
    VITE_AZURE_B2C_AUTHORITY: 'https://glsgroup.b2clogin.com/glsgroup.onmicrosoft.com',
    VITE_AZURE_B2C_CLIENT_ID: '78df48dd-e52a-485f-8509-4ce68403660c',
    VITE_AZURE_B2C_KNOWN_AUTHORITIES: 'glsgroup.b2clogin.com',
    VITE_AZURE_AD_CLIENT_ID: 'f73b84b3-21b3-4d73-942c-e5f7591548e3'
};

let featureFlagsConfig: GlsFeatureFlagsConfig = {};

const getFeatureFlagsConfig = (): GlsFeatureFlagsConfig => {
    return { ...featureFlagsConfig };
};
const setFeatureFlagsConfig = async (): Promise<void> => {
    const featureFlagsResponse: Response = await fetch('/feature-flags.json');
    const config: GlsFeatureFlagsConfig = await featureFlagsResponse.json();
    featureFlagsConfig = config;
};

export { devEnvironment, stageEnvironment, prodEnvironment, getFeatureFlagsConfig, setFeatureFlagsConfig };
