import { jwtDecode } from 'jwt-decode';

const glsGetOidFromToken = (token: string | null): string | null => {
    if (!token) {
        return null;
    }

    const tokenClaims: Record<string, unknown> = jwtDecode(token);

    return (tokenClaims.oid as string) || (tokenClaims.sub as string);
};

export { glsGetOidFromToken };
